import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../providers';
import { BehaviorSubject, combineLatest, Subscription, tap } from 'rxjs';
import { APP_PERMS, WRITE_PERMISSION_PREFIX } from 'common.interfaces';

@Directive({
  selector: '[o8OnlyPermissions]',
  standalone: true,
})
export class OnlyPermissionsDirective implements OnInit, OnDestroy {
  permissions$ = new BehaviorSubject<APP_PERMS[]>([]);
  @Input() set o8OnlyPermissions(value: APP_PERMS | APP_PERMS[]) {
    if (typeof value === 'string') {
      this.permissions$.next([value]);
    } else {
      this.permissions$.next(value);
    }
  }
  subscription!: Subscription;
  hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private userService: UserService,
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const observables = {
      userPermissions: this.userService.getSelfOrImpersonatedUserPermissions(),
      authorizedPermissions: this.permissions$,
    };
    this.subscription = combineLatest(observables)
      .pipe(tap((value) => this.setView(value)))
      .subscribe();
  }

  setView({
    userPermissions,
    authorizedPermissions,
  }: {
    userPermissions: APP_PERMS[];
    authorizedPermissions: APP_PERMS[];
  }) {
    const canView = this.hasAtLeastOneAuthorizedPermission(userPermissions, authorizedPermissions);
    if (!this.hasView && canView) {
      this.container.createEmbeddedView(this.templateRef);
      this.hasView = true;
      return;
    }
    if (this.hasView && !canView) {
      this.container.clear();
      this.hasView = false;
      return;
    }
  }

  hasAtLeastOneAuthorizedPermission(userPermissions: APP_PERMS[], authorizedPermissions: APP_PERMS[]): boolean {
    const requireWritePermissions = authorizedPermissions.some((ap) => ap.startsWith(WRITE_PERMISSION_PREFIX));
    const canBypassPermission = requireWritePermissions
      ? false
      : userPermissions.some((up) => up === APP_PERMS.PIPELINE_READ_ALL);
    return canBypassPermission || userPermissions.some((up) => authorizedPermissions.some((ap) => ap === up));
  }
}
